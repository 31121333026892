import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../layouts/BaseLayout';
import IntroBlock from '../components/IntroBlock/IntroBlock';
import PageBlocks from '../components/PageBlocks/PageBlocks';
import LanguageContext from '../context/Language';

const Page = ({ data }) => {
  const context = useContext(LanguageContext);
  const localData = data[context.lang];

  return (
    <BaseLayout title={localData.title} description={localData.seoDescription}>
      <IntroBlock data={localData} />
      <PageBlocks pageBlocks={localData.pageBlocks} />
    </BaseLayout>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($slug: String!) {
    en: contentfulPage(slug: { eq: $slug }, node_locale: { eq: "en-GB" }) {
      id
      title
      headerImage {
        ...HeaderImageFragment
      }
      pageBlocks {
        __typename
        ...TextBlockFragment
        ...CookieBlockFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      seoDescription
    }
    nl: contentfulPage(slug: { eq: $slug }, node_locale: { eq: "nl-NL" }) {
      id
      title
      headerImage {
        ...HeaderImageFragment
      }
      pageBlocks {
        __typename
        ...TextBlockFragment
        ...CookieBlockFragment
      }
      introduction {
        ...IntroBlockFragment
      }
      seoDescription
    }
  }
`;
