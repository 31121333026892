import { graphql } from 'gatsby';
import React from 'react';
import NewsBlock from './NewsBlock';
// import NoticesBlock from './NoticesBlock';

function LatestNewsBlock({ data }) {
  return <NewsBlock />;
}

export default LatestNewsBlock;
