import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

function HeaderImage({ data }) {
  const image = getImage(data.headerImage);

  return (
    <div
      style={{
        maxHeight: '26rem',
        overflow: 'hidden',
      }}
    >
      <GatsbyImage
        image={image}
        loading="eager"
        layout="fullWidth"
        alt={data.headerImage.title}
      />
    </div>
  );
}

export default HeaderImage;

export const query = graphql`
  fragment HeaderImageFragment on ContentfulAsset {
    gatsbyImageData(
      placeholder: TRACED_SVG
      quality: 100
      width: 3000
      height: 800
    )
    title
  }
`;
