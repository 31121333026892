import React from 'react';
import TextBlock from '../TextBlock/TextBlock';
import CookieBlock from '../CookieBlock/CookieBlock';
import LatestNewsBlock from '../NewsBlock/LatestNewsBlock';

const getBlock = (block) => {
  switch (block.__typename) {
    case 'ContentfulTextBlock':
      return <TextBlock content={block} />;
    case 'ContentfulCookieBlock':
      return <CookieBlock content={block} />;
    case 'ContentfulLatestNewsBlock':
      return <LatestNewsBlock data={block} />;
    default:
      return <div />;
  }
};

function PageBlocks({ pageBlocks }) {
  if (!pageBlocks) {
    return <div />;
  }

  return (
    <div className="pt-5 pb-5">
      {pageBlocks.map((block) => {
        return <div key={block.id}>{getBlock(block)}</div>;
      })}
    </div>
  );
}

export default PageBlocks;
