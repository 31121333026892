import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

function HeroIllustration({ data }) {
  if (data.illustration.gatsbyImageData === null) {
    if (data.illustration.file.url === null) {
      return (
        <img
          src={data.illustration.file.url}
          alt={data.illustration.title}
          style={{ maxWidth: '100%' }}
        />
      );
    }
  }

  const image = getImage(data.illustration);

  return (
    (
      <img
        src={data.illustration.file.url}
        alt={data.illustration.title}
        style={{ maxWidth: '90%' }}
      />
    ) || (
      <GatsbyImage
        image={image}
        alt={data.illustration.title}
        style={{ maxWidth: '100%' }}
        objectFit="contain"
      />
    )
  );
}

export default HeroIllustration;
