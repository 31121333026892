import React from 'react';
import { Col } from 'reactstrap';
import NewsCard from './NewsCard';

function NewsCards({ newsData }) {
  return (
    <>
      {newsData &&
        newsData.map((article) => {
          if (article.thumbnail === null) {
            return null;
          }
          return (
            <Col sm="9" md="6" lg="4" className="mb-5" key={article.id}>
              <NewsCard
                title={article.title}
                shortDescription={article.shortDescription}
                type={article.type}
                date={article.date}
                slug={article.slug}
                image={article.thumbnail}
                callToActionLabel={article.callToActionLabel}
                link={article.link}
              />
            </Col>
          );
        })}
    </>
  );
}

export default NewsCards;
