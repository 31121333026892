import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function NewsCard({
  title,
  shortDescription,
  slug,
  image,
  callToActionLabel,
  date,
  link
}) {
  const newsImage = getImage(image);

  return (
    <Card className="hover-shadow">
      <GatsbyImage
        image={newsImage}
        alt={image.title}
        className="card-img-top"
      />
      <CardBody>
        <CardTitle tag="h5">{title}</CardTitle>
        <CardText>{shortDescription}</CardText>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
            title={title}
          >
            {callToActionLabel}
          </a>
        ) : (
          <Link
            to={`/latest/${date}-${slug}/`}
            className="btn btn-primary"
            title={title}
          >
            {callToActionLabel}
          </Link>
        )}
      </CardBody>
    </Card>
  );
}

export default NewsCard;

export const query = graphql`
  fragment NewsCardFragment on ContentfulNewsArticle {
    date
    callToActionLabel
    title
    type
    id
    shortDescription
    slug
    thumbnail {
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: BLURRED
        resizingBehavior: CROP
        cropFocus: CENTER
        quality: 100
        width: 500
        outputPixelDensities: 2
      )
      title
    }
    link
  }
`;
