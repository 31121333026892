import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import renderRichTextContent from '../../utils/renderRichText';
import HeaderImage from '../HeaderImage/HeaderImage';
import VideoIllustration from '../IllustrationBlock/VideoIllustration';

function IntroBlock({ data, noTitle }) {
  const hasIntro = (introduction) => introduction.body !== null;
  const textAlignment = () => {
    if (data.introduction.illustration) {
      return '';
    }
    return data.introduction.textBlockAlignment ? '' : 'offset-lg-6';
  };
  const imageAlignment = () =>
    data.introduction.textBlockAlignment ? '' : 'order-first';
  return (
    <div>
      {data.headerImage && <HeaderImage data={data} />}
      {data.introduction && (
        <section className={`section-padding-xs bg-light`}>
          <Container>
            <Row>
              <Col md="8" lg="6" className={textAlignment()}>
                {!noTitle && <h1>{data.introduction.title}</h1>}
                {hasIntro(data.introduction) &&
                  renderRichTextContent(data.introduction.body)}
              </Col>
              {data.introduction.kalturaId && (
                <Col
                  md="8"
                  lg="6"
                  className={imageAlignment()}
                  style={{ display: 'flex' }}
                >
                  <VideoIllustration entryId={data.introduction.kalturaId} />
                </Col>
              )}
            </Row>
          </Container>
        </section>
      )}
    </div>
  );
}

export default IntroBlock;

export const query = graphql`
  fragment IntroBlockFragment on ContentfulTextBlock {
    title
    body {
      raw
    }
    kalturaId
    illustration {
      gatsbyImageData(
        layout: FIXED
        placeholder: BLURRED
        quality: 100
        resizingBehavior: SCALE
        width: 550
      )
      file {
        url
      }
      title
    }
    textBlockAlignment
  }
`;
