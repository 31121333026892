const conf_id = Number(process.env.GATSBY_KALTURA_CONFIGID);

// const embedSrcURL = `https://cdnapisec.kaltura.com/p/895641/embedPlaykitJs/uiconf_id/${conf_id}`;
const embedSrcURL = `https://cdnapisec.kaltura.com/p/895641/sp/89564100/embedIframeJs/uiconf_id/${conf_id}/partner_id/895641`;
// const embedloader = `https://cdnapisec.kaltura.com/p/895641/embedPlaykitJs/uiconf_id/${conf_id}?autoembed=true&targetId=kaltura_player_1652438303&entry_id=${entryId}&config[playback]={"autoplay":true}`;
export const embedloaderURL = `https://cdnapisec.kaltura.com/html5/html5lib/v2.95/mwEmbedLoader.php?&urid=2.95&wid=_895641&uiconf_id=${conf_id}&iframeServer=true`;
const embedCSSURL = `https://www.bp.com/content/dam/infrastructure/nav-apps/bp/kaltura-thumbnail-player/videoskin.css`;
const customCSS = `.kWidgetPlayBtn {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2U9IiNmZmYiPiAgICAgICAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMCIgcj0iMTkuNSIvPiAgICAgICAgPHBhdGggZD0iTTE3LjQxIDE0LjQ0YS42MTEuNjExIDAgMCAwLS45MS41MzN2MTAuMDU1YS42MTEuNjExIDAgMCAwIC45MS41MzNsOC45NjctNS4wMjhhLjYxMS42MTEgMCAwIDAgMC0xLjA2NkwxNy40MSAxNC40NHoiLz4gICAgPC9nPjwvc3ZnPg==)
      no-repeat left 20px bottom 0px/50px,
      linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.1)) !important;
      top:70% !important;
      left: 8% !important;
      outline-width: 0;
    }`;
export const createScript = (entryId) => {
  const embedObject = {
    targetId: 'kaltura_player_1652438303',
    wid: '_895641',
    uiconf_id: `${conf_id}`,
    cache_st: 1652438303,
    entry_id: `${entryId}`,
    flashvars: {
      IframeCustomPluginCss1: embedCSSURL,
    },
  };

  const script1 = document?.createElement('script');
  const script3 = document?.createElement('script');
  const script2 = document?.createElement('script');
  const customStyle = document?.createElement('style');

  script1.src = embedSrcURL;
  document?.head?.appendChild(script1);

  customStyle.textContent = customCSS;
  document?.head?.appendChild(customStyle);

  script2.type = 'text/javascript';
  script2.async = false;
  script2.src = embedloaderURL;
  document?.body?.appendChild(script2);

  script3.type = 'text/javascript';
  script3.textContent = `try{
        mw?.setConfig("EmbedPlayer.DisableContextMenu",true);
      kWidget?.thumbEmbed(${JSON.stringify(embedObject)})}
     catch(error){
     }`;
  document?.body?.appendChild(script3);

  return [script1, script2, script3, customStyle];
};
