import Cookies from 'js-cookie';

function cookieDisable(currentLocation) {
  const googleAnalyticsId = (currentEnv) =>
    currentEnv === 'PROD' ? 'UA-39646085-43' : 'UA-50816096-22';

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  }
  Cookies.remove('h2-bp-analytics');
  window[`ga-disable-${googleAnalyticsId(process.env.GATSBY_ENV)}`] = true;
  window.location.reload();
}

export default cookieDisable;
