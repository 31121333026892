import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import renderRichTextContent from '../../utils/renderRichText';
import HeroIllustration from '../HeroBlock/HeroIllustration';
import isReady from '../../utils/isReady';
import VideoIllustration from '../IllustrationBlock/VideoIllustration';

function TextBlock({ bg, content }) {
  return (
    <section className={`pt-5 pb-0 ${bg || 'bg-light-gray-100"'}`}>
      <Container>
        <Row>
          <Col lg="7">
            <h2>{content.title}</h2>
            {renderRichTextContent(content.body)}
          </Col>
          {isReady(content.illustration) && (
            <Col md="8" lg="12" style={{ display: 'flex' }}>
              <HeroIllustration data={content} />
            </Col>
          )}
          {content.kalturaId && (
            <Col md="8" lg="12" style={{ display: 'flex' }}>
              <VideoIllustration entryId={content.kalturaId} />
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default TextBlock;

export const query = graphql`
  fragment TextBlockFragment on ContentfulTextBlock {
    id
    title
    body {
      raw
    }
    kalturaId
    illustration {
      gatsbyImageData(
        layout: FIXED
        placeholder: BLURRED
        quality: 100
        resizingBehavior: SCALE
        width: 550
      )
      file {
        url
      }
      title
    }
  }
`;
