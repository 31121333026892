import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql, Link } from 'gatsby';
import LanguageContext from '../../context/Language';
import NewsCards from '../NewsCard/NewsCards';

function NewsBlock({ latestNewsTitle, latestNewsButtonLabel }) {
  const context = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query HomePageNewsQuery {
      en: allContentfulNewsArticle(
        filter: { node_locale: { eq: "en-GB" } }
        limit: 3
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          ...NewsCardFragment
        }
      }
      nl: allContentfulNewsArticle(
        filter: { node_locale: { eq: "nl-NL" } }
        limit: 3
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          ...NewsCardFragment
        }
      }
    }
  `);

  const newsData = data[context.lang];

  return (
    <section className="section-padding-lg bg-light-gray-100">
      <Container>
        <Row>
          <Col lg="12">
            <h2 className="display-4">{latestNewsTitle}</h2>
          </Col>
        </Row>
        <Row>
          <NewsCards newsData={newsData.nodes} />
        </Row>
        <Row>
          <Col lg={{ size: 2, offset: 10 }} className="text-right">
            <Link to="/latest" className="btn btn-secondary">
              {latestNewsButtonLabel}
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default NewsBlock;
