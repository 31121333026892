import React, { useEffect, useState } from 'react';
import { createScript, embedloaderURL } from '../../utils/videoSetup';

function VideoIllustration({ entryId }) {
  useEffect(() => {
    const [script1, script2, script3, customStyle] = createScript(entryId);
    return () => {
      script1.remove();
      script2.remove();
      script3.remove();
      customStyle.remove();
    };
  }, []);
  return (
    <>
      <div
        id="kaltura_player_1652438303"
        style={{ width: '550px', height: '320px' }}
      ></div>
      <script type="text/javascript" src={embedloaderURL} />
    </>
  );
}

export default VideoIllustration;
