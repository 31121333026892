import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, CustomInput, Button } from 'reactstrap';
import renderRichTextContent from '../../utils/renderRichText';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import cookieCheck from '../../utils/cookieCheck';
import cookieActivate from '../../utils/cookieActivate';
import cookieDisable from '../../utils/cookieDisable';
import { setHideCookieBanner } from '../../utils/cookieBannerCheck';

function CookieBlock({ bg, content }) {
  const cookiesAllowed = cookieCheck();
  const currentLocation = useLocation();
  const [isChecked, setIsChecked] = useState(cookiesAllowed);

  const saveChanges = () => {
    if (isChecked) {
      cookieActivate(currentLocation, initializeAndTrack);
    } else {
      cookieDisable(currentLocation);
    }
    setHideCookieBanner();
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.location.reload();
    }
  };

  const toggle = () => {
    const newState = !isChecked;
    if (newState === true) {
      setIsChecked(true);
    }
    if (newState === false) {
      setIsChecked(false);
    }

    // setHideCookieBanner();
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      // window.location.reload();
    }
  };

  return (
    <section
      className={`pt-5 pb-5 ${bg || 'bg-light-gray-100"'}`}
      data-testid="cookie-block"
    >
      <Container>
        {content.showToggle && (
          <Row className="pb-2">
            <Col lg="6">
              <label htmlFor="toggle" className="display-5">
                {content.title}
              </label>
            </Col>
            <Col lg="1" className="mt-3">
              <CustomInput
                type="switch"
                id="toggle"
                className="custom-control-sm"
                defaultChecked={isChecked}
                onClick={toggle}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col lg="7">
            <>
              {!content.showToggle && <h2>{content.title}</h2>}
              {renderRichTextContent(content.description)}
            </>
          </Col>
        </Row>
        {content.showToggle && (
          <Row>
            <Col lg="7" className="text-right">
              <Button onClick={saveChanges}>{content.buttonLabel}</Button>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
}

export default CookieBlock;

export const query = graphql`
  fragment CookieBlockFragment on ContentfulCookieBlock {
    id
    title
    description {
      raw
    }
    showToggle
    buttonLabel
  }
`;
